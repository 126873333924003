<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title class="primary">
            <span class="text-h5">Add New Sale</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-data-table
                      :items-per-page="-1"
                      :headers="headers"
                      :items="form.items"
                      hide-default-footer
                      class="elevation-1">
                    <template v-slot:top>
                      <v-toolbar flat>
                        <v-toolbar-title>Products Sold</v-toolbar-title>
                      </v-toolbar>
                    </template>
                    <template v-slot:item.name="{ item }">
                      <h2>{{item.name}}</h2>
                      <p>SKU: {{item.sku}}</p>
                    </template>
                    <template v-slot:item.unit_price="{ item }">
                      <div style="max-width: 70px">
                        {{item.unit_price}}
                      </div>
                    </template>
                    <template v-slot:item.quantity="{ item }">
                      <div style="max-width: 50px">
                        x{{item.quantity}}
                      </div>
                    </template>
                    <template v-slot:item.total="{ item }">
                      <div style="max-width: 100px">
                        {{calculateTotal(item.unit_price, item.quantity) | currency('PHP')}}
                      </div>
                    </template>
                  </v-data-table>
                </v-col>
                <v-col cols="6">
                  <div class="text-right font-weight-bold headline">
                    <div>
                      <table style="width: 100%">
                        <tr>
                          <td>GROSS TOTAL:</td>
                          <td>{{form.gross_total}}</td>
                        </tr>
                        <tr>
                          <td>
                            NET TOTAL:
                          </td>
                          <td class="text-right">
                            {{form.net_total}}
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </v-col>
                <v-col cols="6">
                  <div class="text-right font-weight-bold headline">
                    <div>
                      <table style="width: 100%">
                        <tr>
                          <td>
                            PAID AMOUNT:
                          </td>
                          <td class="text-right">
                            {{form.customer_paid_amount}}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            CHANGE:
                          </td>
                          <td class="text-right">
                            {{form.customer_change | currency('PHP')}}
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </v-col>
                <v-col cols="12">
                  <table class="text-right font-weight-bold pa-2">
                    <tr>
                      <td class="pa-2">Customer Name:</td>
                      <td class="pa-2">{{form.customer_name}}</td>
                    </tr>
                    <tr>
                      <td class="pa-2">Payment Type:</td>
                      <td class="pa-2">{{form.payment_type}}</td>
                    </tr>
                    <tr>
                      <td class="pa-2">Remarks:</td>
                      <td class="pa-2">{{form.remarks}}</td>
                    </tr>
                  </table>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import moment from "moment";
export default {
  name: 'SalesView',
  data() {
    return {
      form: {
        customer_name: '',
        customer_id: '',
        gross_total: 0.00,
        net_total: 0.00,
        payment_type: '',
        date_issued: moment().format('YYYY-MM-DD'),
        due_date: moment().format('YYYY-MM-DD'),
        remarks: 'N/A',
        customer_paid_amount: 0.00,
        customer_change: 0.00,
        items: [],
      },
      options: {
        paymentTypes: ['CASH','CREDIT','PDC']
      },
      headers: [
        { text: 'Product', value: 'name', align: 'start', sortable: false },
        { text: 'Unit Price', value: 'unit_price', align: 'start', sortable: false },
        { text: 'Quantity', value: 'quantity', align: 'start', sortable: false },
        { text: 'Total', value: 'total', align: 'start', sortable: false },
      ],
      flags: {}
    }
  },
  mounted() {
    this.loadInvoice(this.$route.params.id);
  },
  methods: {
    calculateTotal(unitPrice, qty) {
      return (unitPrice * qty);
    },
    loadInvoice(id) {

      const self = this;

      self.flags.isSaving = true;

      window.axios.get(window.ANA.api_url + '/pos/invoices/' + id,self.form).then(function(response) {
        console.log(response);
        let data = response.data.data;
        self.form.customer_id = data.customer_id;
        self.form.customer_name = data.customer_name;
        self.form.gross_total = data.gross_total;
        self.form.net_total = data.net_total;
        self.form.payment_type = data.payment_type;
        self.form.date_issued = data.date_issued;
        self.form.due_date = data.due_date;
        self.form.remarks = data.remarks;
        self.form.customer_paid_amount = data.data.customer_paid_amount;
        self.form.customer_change = data.data.customer_change;
        self.form.items = data.data.items;
      });
    }
  }
}
</script>