<template>
  <v-card>
    <v-data-table
        :page.sync="currentPage"
        :items-per-page="perPage"
        :headers="headers"
        :items="items"
        hide-default-footer
        class="elevation-1">
      <template v-slot:top>
        <v-toolbar flat>
          <v-text-field v-model="searchByName" hide-details prepend-icon="mdi-magnify" single-line label="Filter By Name"></v-text-field>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn color="primary" @click="select(item)">Select</v-btn>
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination
          v-model="currentPage"
          :length="pageCount">
      </v-pagination>
    </div>
  </v-card>
</template>

<script>
import {debounce} from "../common/helpers";
export default {
  name: 'SelectCustomer',
  props: {
    propSelectCallback: {
      required: true
    }
  },
  data () {
    return {
      headers: [
        { text: 'Name', value: 'name', align: 'start', sortable: false },
        { text: 'Address', value: 'address', align: 'start', sortable: false },
        { text: 'Contact Number', value: 'contact_number', align: 'start', sortable: false },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      items: [],
      currentPage: 1,
      perPage: 10,
      pageCount: 0,
      isSaving: false,
      searchByName: '',
    }
  },
  watch: {
    currentPage: function () {
      this.loadCustomers();
    },
    searchByName: debounce(function (newVal) {
      this.searchByName = newVal
      this.loadCustomers();
    }, 800),
  },
  mounted() {
    const self = this;
    self.loadCustomers();
  },
  methods: {
    select(supplier) {
      this.propSelectCallback(supplier);
    },
    loadCustomers(cb) {

      let self = this;
      let params = {
        name: self.searchByName,
        current_page: self.currentPage,
        per_page: self.perPage,
      };

      window.axios.get(window.ANA.api_url + '/accounts/customers',{params: params}).then(function(response) {
        self.items = response.data.data.items;
        self.pageCount = response.data.data.total_pages;
        (cb || Function)();
      });
    },
  },
}
</script>