<template>
  <div>
    <v-card>
      <v-card-title class="primary">
        <span class="text-h5">Editing Product: {{propProduct.name}}</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="6">
              <v-text-field v-model="propProduct.name" label="Name"></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field :disabled="true" v-model="propProduct.sku" label="SKU"></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-combobox v-model="propProduct.type" :items="productTypes" label="Product Type"></v-combobox>
            </v-col>
            <v-col cols="4">
              <v-text-field v-model="propProduct.retail_price" label="Retail Price"></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field v-model="propProduct.stock_count" label="Stock Count"></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn :disabled="flags.isSaving" color="warning darken-1" @click="propEditCancelCallback">
          Cancel
        </v-btn>
        <v-btn :disabled="flags.isSaving" color="blue darken-1" @click="update">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
export default {
  name: 'EditProductForm',
  props: {
    propProduct: {
      required: true
    },
    propProductTypes: {
      required: true
    },
    propEditCancelCallback: {
      required: true
    },
    propEditSuccessCallback: {
      required: true
    },
    propEditFailedCallback: {
      required: true
    }
  },
  mounted() {
    this.productTypes = this.propProductTypes;
  },
  data () {
    return {
      productTypes: [],
      flags: {
        isSaving: false
      }
    }
  },
  methods: {
    update() {

      let self = this;

      window.axios.put(window.ANA.api_url + '/store/products/' + self.propProduct.id, self.propProduct).then(function(response) {
        self.propEditSuccessCallback(response);
      }).catch(function (error) {
        self.propEditFailedCallback(error);
      }).then(function (){
        self.flags.isSaving = false;
      });
    }
  }
}
</script>