<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-data-table
            :page.sync="currentPage"
            :items-per-page="perPage"
            :headers="headers"
            :items="items"
            hide-default-footer
            class="elevation-1">
          <template v-slot:top>
            <v-toolbar flat>
              <v-text-field v-model="searchByName" hide-details prepend-icon="mdi-magnify" single-line label="Filter By Name"></v-text-field>
              <v-spacer></v-spacer>
              <v-btn color="success" depressed @click="dialogs.create = true">ADD NEW SUPPLIER</v-btn>
            </v-toolbar>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon title="Edit" small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
            <v-icon title="Delete" small class="mr-2" @click="deleteItem(item)">mdi-delete</v-icon>
          </template>
        </v-data-table>
        <div class="text-center pt-2">
          <v-pagination
              v-model="currentPage"
              :length="pageCount">
          </v-pagination>
        </div>
      </v-col>
    </v-row>
    <v-row justify="center">

      <!-- dialog for stocks create -->
      <v-dialog v-model="dialogs.create" persistent max-width="600px">
        <add-supplier-form
            :prop-cancel-callback="cancel"
            :prop-add-supplier-failed-callback="addSupplierFailedCB"
            :prop-add-supplier-success-callback="addSupplierSuccessCB">
        </add-supplier-form>
      </v-dialog>

    </v-row>
  </v-container>
</template>

<script>
import {debounce} from "../../common/helpers";
import AddSupplierForm from "../../components/AddSupplierForm";
export default {
  name: 'Supplier',
  components: {AddSupplierForm},
  watch: {
    currentPage: function () {
      this.loadSuppliers();
    },
    'searchByName': debounce(function () {
      this.loadSuppliers();
    },800),
  },
  data () {
    return {
      headers: [
        { text: 'Name', value: 'name', align: 'start', sortable: false },
        { text: 'Address', value: 'address', align: 'start', sortable: false },
        { text: 'Contact Number', value: 'contact_number', align: 'start', sortable: false },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      items: [],
      currentPage: 1,
      perPage: 10,
      pageCount: 0,
      isSaving: false,
      searchByName: '',
      dialogs: {
        create: false,
        edit: false,
      },
    }
  },
  mounted() {
    const self = this;
    self.loadSuppliers();
  },
  methods: {
    cancel() {
      this.dialogs.create = false;
    },
    editItem(item) {
      console.log(item)
    },
    deleteItem(item) {
      console.log(item)
    },
    addSupplierSuccessCB(data) {
      console.log(data);
      this.dialogs.create = false;
      this.loadSuppliers();
      this.$store.commit('showSnackbar',{
        message: "New Supplier Added Successfully.",
        color: 'success',
        duration: 3000
      });
    },
    addSupplierFailedCB(data) {
      console.log(data);
      this.$store.commit('showSnackbar',{
        message: "Failed to add new supplier.",
        color: 'red',
        duration: 3000
      });
    },
    loadSuppliers(cb) {

      let self = this;
      let params = {
        name: self.searchByName,
        current_page: self.currentPage,
        per_page: self.perPage,
      };

      window.axios.get(window.ANA.api_url + '/accounts/supplier',{params: params}).then(function(response) {
        self.items = response.data.data.items;
        self.pageCount = response.data.data.total_pages;
        (cb || Function)();
      });
    }
  }
}
</script>