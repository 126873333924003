<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title class="primary">
            <span class="text-h5">Add New Sale</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="4">
                  <v-text-field v-model="form.customer_name" label="Customer Name" @click="flags.showDialogSelectCustomer = true"></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-combobox v-model="form.payment_type" :items="options.paymentTypes" label="Payment Type"></v-combobox>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                      v-mask="'####-##-##'"
                      v-model="form.date_sold"
                      label="Select Sold Date (YYYY-MM-DD)"
                      prepend-icon="mdi-calendar"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-data-table
                      :items-per-page="-1"
                      :headers="headers"
                      :items="form.items"
                      hide-default-footer
                      class="elevation-1">
                    <template v-slot:top>
                      <v-toolbar flat>
                        <v-toolbar-title>Products Sold</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn color="success" @click="flags.showDialogSelectProduct = true">Add Item</v-btn>
                        <v-btn class="ml-2" v-if="flags.scanMode === false" @click="flags.scanMode = true">START SCAN</v-btn>
                        <v-btn class="ml-2" v-if="flags.scanMode === true" @click="flags.scanMode = false">SCANNING..</v-btn>
                      </v-toolbar>
                    </template>
                    <template v-slot:item.name="{ item }">
                      <h2>{{item.name}}</h2>
                      <p>SKU: {{item.sku}}</p>
                    </template>
                    <template v-slot:item.unit_price="{ item }">
                      <div style="max-width: 70px">
                        <v-text-field v-model="item.unit_price"></v-text-field>
                      </div>
                    </template>
                    <template v-slot:item.quantity="{ item }">
                      <div style="max-width: 50px">
                        <v-text-field v-model="item.quantity"></v-text-field>
                      </div>
                    </template>
                    <template v-slot:item.total="{ item }">
                      <div style="max-width: 100px">
                        {{calculateTotal(item.unit_price, item.quantity) | currency('PHP')}}
                      </div>
                    </template>
                    <template v-slot:item.actions="{ item, index }">
                      <v-icon title="Delete" small class="mr-2" x-large @click="removeItem(index)">mdi-close-circle</v-icon>
                    </template>
                  </v-data-table>
                </v-col>
                <v-col cols="12">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="6">
                  <div class="text-right font-weight-bold headline">
                    <div>
                      <table style="width: 100%">
                        <tr>
                          <td>GROSS TOTAL:</td>
                          <td>{{calculateGrossTotal() | currency('PHP')}}</td>
                        </tr>
                        <tr>
                          <td>
                            NET TOTAL:
                          </td>
                          <td class="text-right">
                            <v-text-field v-model="form.net_total"></v-text-field>
                          </td>
                        </tr>
                        <tr>
                          <td><v-btn :disabled="form.net_total === 0" @click="applySeniorDiscount">Apply Senior Discount</v-btn></td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </v-col>
                <v-col cols="6">
                  <div class="text-right font-weight-bold headline">
                    <div>
                      <table style="width: 100%">
                        <tr>
                          <td>
                            PAID AMOUNT:
                          </td>
                          <td class="text-right">
                            <v-text-field v-model="form.customer_paid_amount"></v-text-field>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            CHANGE:
                          </td>
                          <td class="text-right">
                            {{form.customer_change | currency('PHP')}}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <v-btn :disabled="form.customer_paid_amount === 0" @click="calculateChange" class="mr-2">Calculate</v-btn>
                            <v-btn @click="calculateChangeReset">Reset</v-btn>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </v-col>
                <v-col cols="12">
                  <v-textarea v-model="form.remarks" label="Remarks"></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn :disabled="flags.isSaving" color="warning darken-1" :to="{name:'Admin.sales'}">
              Cancel
            </v-btn>
            <v-btn :disabled="flags.isSaving" color="blue darken-1" @click="saveSale">
              Create Sales Record
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="flags.showDialogSelectProduct" max-width="900px">
        <select-product :prop-select-callback="selectProductCallback"></select-product>
      </v-dialog>
      <v-dialog v-model="flags.showDialogSelectCustomer" max-width="900px">
        <select-customer :prop-select-callback="selectCustomerCallback"></select-customer>
      </v-dialog>
    </v-row>
  </v-container>
</template>
<script>
import SelectProduct from "../../components/SelectProduct";
import SelectCustomer from "../../components/SelectCustomer";
import moment from "moment";
import {debounce} from "../../common/helpers";
export default {
  name: 'SalesNew',
  components: {SelectCustomer, SelectProduct},
  data() {
    return {
      form: {
        customer_name: '',
        customer_id: '',
        gross_total: 0.00,
        net_total: 0.00,
        payment_type: '',
        date_sold: moment().format('YYYY-MM-DD'),
        date_issued: moment().format('YYYY-MM-DD'),
        due_date: moment().format('YYYY-MM-DD'),
        remarks: 'N/A',
        customer_paid_amount: 0.00,
        customer_change: 0.00,
        items: [],
      },
      options: {
        paymentTypes: ['CASH','CREDIT','PDC']
      },
      headers: [
        { text: 'Actions', value: 'actions', sortable: false },
        { text: 'Product', value: 'name', align: 'start', sortable: false },
        { text: 'Unit Price', value: 'unit_price', align: 'start', sortable: false },
        { text: 'Quantity', value: 'quantity', align: 'start', sortable: false },
        { text: 'Total', value: 'total', align: 'start', sortable: false },
      ],
      flags: {
        isSaving: false,
        showDialogSelectProduct: false,
        showDialogSelectCustomer: false,
        seniorDiscountApplied: false,
        scanMode: false
      }
    }
  },
  watch: {
    "flags.scanMode": debounce(function (newVal) {
      if(newVal === true) this.loadBarcode();
    }, 800)
  },
  mounted() {
    this.loadStockLocations()
  },
  methods: {
    calculateChangeReset() {
      this.form.customer_paid_amount = 0.00;
      this.form.customer_change = 0.00;
    },
    applySeniorDiscount() {
      this.form.net_total = ((this.form.net_total / 1.12) * .80).toFixed(2)
      this.flags.seniorDiscountApplied = true
      this.form.remarks += " ***SENIOR DISCOUNT APPLIED*** "
    },
    calculateTotal(unitPrice, qty) {
      return (unitPrice * qty);
    },
    calculateGrossTotal() {
      let grossTotal = 0.00
      this.form.items.forEach(function(item) {
        grossTotal += (item.unit_price * item.quantity)
      })
      this.form.gross_total = grossTotal
      return grossTotal
    },
    calculateChange() {
      this.form.customer_change = this.form.customer_paid_amount - this.form.net_total
    },
    removeItem(index) {
      this.form.items.splice(index,1)
    },
    resetForm() {
      this.form.customer_name = '';
      this.form.customer_id = '';
      this.form.gross_total = '';
      this.form.net_total = '';
      this.form.payment_type = 'CASH';
      this.form.date_issued = moment().format('YYYY-MM-DD');
      this.form.due_date = moment().format('YYYY-MM-DD');
      this.form.remarks = 'N/A';
      this.form.items = [];
    },
    selectProductCallback(product) {

      console.log(product)

      let item = {}
      item.product_id = product.id;
      item.name = product.name;
      item.sku = product.sku;
      item.unit_price = product.retail_price;
      item.quantity = 1;

      this.form.items.push(item);
      this.flags.showDialogSelectProduct = false;
      console.log(this.form.items);
    },
    selectCustomerCallback(customer) {
      console.log(customer)
      this.form.customer_id = customer.id;
      this.form.customer_name = customer.name;
      this.flags.showDialogSelectCustomer = false;
    },
    loadStockLocations() {
      let self = this;
      window.axios.get(window.ANA.api_url + '/store/stock-locations',{}).then(function(response) {
        self.options.stockLocations = response.data.data;
      });
    },
    saveSale() {

      const self = this;

      self.flags.isSaving = true;

      window.axios.post(window.ANA.api_url + '/pos/invoices',self.form).then(function(response) {
        console.log(response);
        self.$store.commit('showSnackbar',{
          message: "New Sales Record Created Successfully.",
          color: 'success',
          duration: 3000
        });
      }).catch(function (error) {
        console.log(error);
        self.$store.commit('showSnackbar',{
          message: "Failed to created sales record.",
          color: 'red',
          duration: 3000
        });
      }).then(function (){
        self.flags.isSaving = false;
        self.resetForm();
        self.$router.push({name:'Admin.sales'});
      });
    },
    loadBarcode() {
      let self = this;
      let params = {
        device_code: window.ANA.deviceID,
      };

      window.axios.get(window.ANA.api_url + '/store/getbcscan',{params: params}).then(function(response) {

        if(response.data.data.new === 'no') {
          console.log("No new Barcode")
        } else {
          console.log(response.data.data.barcode)
          self.loadProductByBarcode(response.data.data.barcode)
        }

        if(self.flags.scanMode === true) {
          setTimeout(function(){
            self.loadBarcode();
          },2000);
        }
      });
    },
    loadProductByBarcode(barcode) {

      let self = this;
      let params = {
        barcode: barcode,
      };

      window.axios.get(window.ANA.api_url + '/store/products/get-by-barcode',{params: params}).then(function(response) {
        self.selectProductCallback(response.data.data);
        self.playBeep();
      });
    },
    playBeep() {
      let context = new AudioContext();
      let oscillator = context.createOscillator();
      oscillator.type = "sine";
      oscillator.frequency.value = 800;
      oscillator.connect(context.destination);
      oscillator.start();
      // Beep for 500 milliseconds
      setTimeout(function () {
        oscillator.stop();
      }, 100);
    }
  }
}
</script>