<template>
  <div>
    <v-card>
      <v-card-title class="primary">
        <span class="text-h5">Edit Stock</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-alert color="warning">
                When you edit QUANTITY, please mind that the product stock count is not automatically updated. So please
                update the stock count of the affected product accordingly on the PRODUCTS TAB.
              </v-alert>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="form.product_name" :disabled="true" label="Product"></v-text-field>
              <v-btn @click="flags.showDialogSelectProduct = true">Change Product</v-btn>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="form.supplier_name" :disabled="true" label="Supplier"></v-text-field>
              <v-btn @click="flags.showDialogSelectSupplier = true">Change Supplier</v-btn>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="form.batch_number" label="Batch Number"></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                  v-mask="'####-##-##'"
                  v-model="form.date_expire"
                  label="Select Date Expiry (YYYY-MM-DD)"
                  prepend-icon="mdi-calendar"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="form.bought_price" label="Price Bought"></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="form.quantity" label="Quantity"></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="form.dr_number" label="DR Number"></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-combobox v-model="form.location" :items="options.stockLocations" label="Stock Locations"></v-combobox>
            </v-col>
            <v-col cols="12">
              <v-textarea v-model="form.remarks" label="Remarks"></v-textarea>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn :disabled="flags.isSaving" color="warning darken-1" @click="cancel">
          Cancel
        </v-btn>
        <v-btn :disabled="flags.isSaving" color="blue darken-1" @click="updateStock">
          Update Stock
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="flags.showDialogSelectProduct" max-width="900px">
      <select-product :prop-select-callback="selectProductCallback"></select-product>
    </v-dialog>
    <v-dialog v-model="flags.showDialogSelectSupplier" max-width="900px">
      <select-supplier :prop-select-callback="selectSupplierCallback"></select-supplier>
    </v-dialog>
  </div>
</template>
<script>
import SelectProduct from "./SelectProduct";
import SelectSupplier from "./SelectSupplier";
import moment from "moment";
export default {
  name: 'EditStockForm',
  components: {SelectProduct, SelectSupplier},
  props: {
    propStock: {
      required: true
    },
    propEditStockSuccessCallback: {
      required: true
    },
    propEditStockFailedCallback: {
      required: true
    },
    propCancelCallback: {
      required: true
    },
  },
  data() {
    return {
      form: {
        id: '',
        product_name: '',
        product_id: '',
        supplier_name: '',
        supplier_id: '',
        batch_number: '',
        dr_number: '',
        date_expire: moment().add(15,'days').format('YYYY-MM-DD'),
        bought_price: 0.00,
        quantity: 1,
        location: '',
        remarks: 'N/A',
      },
      options: {
        stockLocations: []
      },
      flags: {
        isSaving: false,
        showDialogSelectProduct: false,
        showDialogSelectSupplier: false,
      }
    }
  },
  mounted() {
    this.form.id = this.propStock.id;
    this.form.product_name = this.propStock.product.name;
    this.form.product_id = this.propStock.product.id;
    this.form.supplier_id = this.propStock.supplier.id;
    this.form.supplier_name = this.propStock.supplier.name;
    this.form.batch_number = this.propStock.batch_number;
    this.form.dr_number = this.propStock.dr_number;
    this.form.date_expire = this.propStock.date_expire;
    this.form.bought_price = this.propStock.bought_price;
    this.form.quantity = this.propStock.quantity;
    this.form.location = this.propStock.location;
    this.form.remarks = this.propStock.remarks;
    this.loadStockLocations()

    console.log(this.propStock.id)
  },
  methods: {
    resetForm() {
      this.form.id = '';
      this.form.product_name = '';
      this.form.product_id = '';
      this.form.supplier_name = '';
      this.form.supplier_id = '';
      this.form.batch_number = '';
      this.form.date_expire = moment().add(15,'days').format('YYYY-MM-DD');
      this.form.bought_price = 0.00;
      this.form.quantity = 1;
      this.form.location = '';
      this.form.remarks = 'N/A';
    },
    cancel() {
      this.propCancelCallback();
    },
    selectProductCallback(product) {
      this.form.product_name = product.name;
      this.form.product_id = product.id;
      this.flags.showDialogSelectProduct = false
    },
    selectSupplierCallback(supplier) {
      this.form.supplier_name = supplier.name;
      this.form.supplier_id = supplier.id;
      this.flags.showDialogSelectSupplier = false
    },
    loadStockLocations() {
      let self = this;
      window.axios.get(window.ANA.api_url + '/store/stock-locations',{}).then(function(response) {
        self.options.stockLocations = response.data.data;
      });
    },
    updateStock() {
      const self = this;

      self.flags.isSaving = true;

      window.axios.put(window.ANA.api_url + '/store/stocks/' + self.form.id,self.form).then(function(response) {
        self.propEditStockSuccessCallback(response);
      }).catch(function (error) {
        self.propEditStockFailedCallback(error);
      }).then(function (){
        self.flags.isSaving = false;
        self.resetForm();
      });
    }
  }
}
</script>