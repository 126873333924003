var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',{staticClass:"primary"},[_c('span',{staticClass:"text-h5"},[_vm._v("Add New Sale")])]),_c('v-divider'),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"items-per-page":-1,"headers":_vm.headers,"items":_vm.form.items,"hide-default-footer":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Products Sold")])],1)]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('h2',[_vm._v(_vm._s(item.name))]),_c('p',[_vm._v("SKU: "+_vm._s(item.sku))])]}},{key:"item.unit_price",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"max-width":"70px"}},[_vm._v(" "+_vm._s(item.unit_price)+" ")])]}},{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"max-width":"50px"}},[_vm._v(" x"+_vm._s(item.quantity)+" ")])]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"max-width":"100px"}},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.calculateTotal(item.unit_price, item.quantity),'PHP'))+" ")])]}}])})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"text-right font-weight-bold headline"},[_c('div',[_c('table',{staticStyle:{"width":"100%"}},[_c('tr',[_c('td',[_vm._v("GROSS TOTAL:")]),_c('td',[_vm._v(_vm._s(_vm.form.gross_total))])]),_c('tr',[_c('td',[_vm._v(" NET TOTAL: ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.form.net_total)+" ")])])])])])]),_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"text-right font-weight-bold headline"},[_c('div',[_c('table',{staticStyle:{"width":"100%"}},[_c('tr',[_c('td',[_vm._v(" PAID AMOUNT: ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.form.customer_paid_amount)+" ")])]),_c('tr',[_c('td',[_vm._v(" CHANGE: ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.form.customer_change,'PHP'))+" ")])])])])])]),_c('v-col',{attrs:{"cols":"12"}},[_c('table',{staticClass:"text-right font-weight-bold pa-2"},[_c('tr',[_c('td',{staticClass:"pa-2"},[_vm._v("Customer Name:")]),_c('td',{staticClass:"pa-2"},[_vm._v(_vm._s(_vm.form.customer_name))])]),_c('tr',[_c('td',{staticClass:"pa-2"},[_vm._v("Payment Type:")]),_c('td',{staticClass:"pa-2"},[_vm._v(_vm._s(_vm.form.payment_type))])]),_c('tr',[_c('td',{staticClass:"pa-2"},[_vm._v("Remarks:")]),_c('td',{staticClass:"pa-2"},[_vm._v(_vm._s(_vm.form.remarks))])])])])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }