import Vue from 'vue'
import Main from './Main.vue'
import vuetify from './plugins/vuetify'
import router from './router.js'
import VueProgressBar from 'vue-progressbar'
import AxiosAjaxDetct from './common/AxiosAjaxDetect';
import store from './common/store'
import axios from "axios";
import Vue2Filters from 'vue2-filters'
import VueMask from 'v-mask'

Vue.use(VueMask);
Vue.config.productionTip = false

// set axios
window.axios = axios
window.axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters.getSessionToken;

// vue progress bar
const options = {
  color: '#459ce3',
  failedColor: '#874b4b',
  thickness: '10px',
  transition: {
    speed: '0.2s',
    opacity: '0.6s',
    termination: 300
  },
  autoRevert: true,
  location: 'top',
  inverse: false
}
Vue.use(VueProgressBar, options)
Vue.use(Vue2Filters)

new Vue({
  el: '#app',
  vuetify,
  router,
  store,
  render: h => h(Main),
  mounted() {

    const self = this;

    // progress bar top
    AxiosAjaxDetct.init(()=>{
      self.$Progress.start();
    },()=>{
      self.$Progress.finish();
    });
  },
});
