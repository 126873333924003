<template>
  <v-card>
    <v-data-table
        :page.sync="currentPage"
        :items-per-page="perPage"
        :headers="headers"
        :items="items"
        hide-default-footer
        class="elevation-1">
      <template v-slot:top>
        <v-toolbar flat>
          <v-text-field v-model="searchByName" hide-details prepend-icon="mdi-magnify" single-line label="Filter By Name"></v-text-field>
          <v-text-field v-model="searchByBarcode" hide-details prepend-icon="mdi-magnify" single-line label="Filter By Barcode"></v-text-field>
          <v-text-field v-model="searchBySKU" hide-details prepend-icon="mdi-magnify" single-line label="Filter By SKU"></v-text-field>
        </v-toolbar>
      </template>
      <template v-slot:item.name="{ item }">
        <div style="padding: 5px;">
          <h3>{{item.name}}</h3>
          <div style="font-size: 12px;">
            <div><i>Barcode: {{item.barcode}}</i></div>
            <div><i>SKU: {{item.sku}}</i></div>
          </div>
        </div>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn color="primary" :disabled="(propPurpose === 'invoice' && item.stock_count === 0)" @click="select(item)">Select</v-btn>
      </template>
      <template v-slot:item.stock_count="{ item }">
        {{item.stock_count}}
        <v-chip v-if="(item.stock_count > 5)"
                class="ma-2"
                color="success">
          In Stock
        </v-chip>
        <v-chip v-else-if="(item.stock_count <= 4 && item.stock_count !== 0)"
                class="ma-2"
                color="warning">
          Low in Stocks
        </v-chip>
        <v-chip v-else
                class="ma-2"
                color="red">
          Out Of Stock
        </v-chip>
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination
          v-model="currentPage"
          :length="pageCount">
      </v-pagination>
    </div>
  </v-card>
</template>

<script>
import {debounce} from "../common/helpers";
export default {
  name: 'Products',
  props: {
    propSelectCallback: {
      required: true
    },
    propPurpose: {
      type: String,
      default: 'general' //invoice or general
    }
  },
  data () {
    return {
      headers: [
        { text: 'Name', value: 'name', align: 'start', sortable: false },
        { text: 'Type', value: 'type', align: 'start', sortable: false },
        { text: 'SKU', value: 'sku', align: 'start', sortable: false },
        { text: 'Retail Price', value: 'retail_price', align: 'start', sortable: false },
        { text: 'Stock Count', value: 'stock_count' , align: 'start, sortable: false'},
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      items: [],
      currentPage: 1,
      perPage: 10,
      pageCount: 0,
      isSaving: false,
      searchByName: '',
      searchByBarcode: '',
      searchBySKU: '',
    }
  },
  watch: {
    currentPage: function () {
      this.loadProducts();
    },
    searchByName: debounce(function (newVal) {
      this.searchByName = newVal
      this.loadProducts();
    }, 800),
    searchByBarcode: debounce(function (newVal) {
      this.searchByBarcode = newVal
      this.loadProducts();
    }, 800),
    searchBySKU: debounce(function (newVal) {
      this.searchBySKU = newVal
      this.loadProducts();
    }, 800)
  },
  mounted() {
    const self = this;
    self.loadProducts();
  },
  methods: {
    select(product) {
      this.propSelectCallback(product);
    },
    loadProducts(cb) {

      let self = this;
      let params = {
        name: self.searchByName,
        barcode: self.searchByBarcode,
        sku: self.searchBySKU,
        current_page: self.currentPage,
        per_page: self.perPage,
      };

      window.axios.get(window.ANA.api_url + '/store/products',{params: params}).then(function(response) {
        self.items = response.data.data.items;
        self.pageCount = response.data.data.total_pages;
        (cb || Function)();
      });
    },
  },
}
</script>