<template>
  <div>
    <v-card>
      <v-card-title class="primary">
        <span class="text-h5">Add New Stock</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field v-model="form.dr_number" label="DR Number"></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="form.item_name" label="Item Name"></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="form.item_code" label="Item Code"></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field v-model="form.quantity" label="Quantity"></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field v-model="form.unit_price" label="Unit Price"></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field v-model="form.total" label="Total"></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field v-model="form.batch_number" label="Batch Number"></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                  v-mask="'####-##-##'"
                  v-model="form.date_expire"
                  label="Select Date Expiry (YYYY-MM-DD)"
                  prepend-icon="mdi-calendar"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-combobox v-model="form.location" :items="options.stockLocations" label="Stock Locations"></v-combobox>
            </v-col>
            <v-col cols="12">
              <v-text-field v-model="form.supplier_name" label="Supplier"></v-text-field>
              <v-btn @click="flags.showDialogSelectSupplier = true">Select Supplier</v-btn>
            </v-col>
            <v-col cols="12">
              <v-textarea v-model="form.remarks" label="Remarks"></v-textarea>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn :disabled="flags.isSaving" color="warning darken-1" @click="cancel">
          Cancel
        </v-btn>
        <v-btn :disabled="flags.isSaving" color="blue darken-1" @click="saveStock">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="flags.showDialogSelectSupplier" max-width="900px">
      <select-supplier :prop-select-callback="selectSupplierCallback"></select-supplier>
    </v-dialog>
  </div>
</template>
<script>
import SelectSupplier from "./SelectSupplier";
import moment from "moment";
export default {
  components: {SelectSupplier},
  props: {
    propAddStockSuccessCallback: {
      required: true
    },
    propAddStockFailedCallback: {
      required: true
    },
    propCancelCallback: {
      required: true
    },
  },
  data() {
    return {
      form: {
        dr_number: '',
        item_name: '',
        item_code: '',
        quantity: 1,
        unit_price: 0.00,
        total: 0.00,
        supplier_name: '',
        supplier_id: '',
        batch_number: 'N/A',
        date_expire: moment().add(15,'days').format('YYYY-MM-DD'),
        location: '',
        remarks: 'N/A',
      },
      options: {
        stockLocations: []
      },
      flags: {
        isSaving: false,
        showDialogSelectSupplier: false,
      }
    }
  },
  mounted() {
    this.loadStockLocations()
  },
  methods: {
    resetForm() {
      this.form.item_name = '';
      this.form.item_code = '';
      this.form.quantity = 1;
      this.form.unit_price = 0.00;
      this.form.total = 0.00;
      this.form.supplier_name = '';
      this.form.supplier_id = '';
      this.form.batch_number = 'N/A';
      this.form.date_expire = moment().add(15,'days').format('YYYY-MM-DD');
      this.form.remarks = 'N/A';
    },
    cancel() {
      this.propCancelCallback();
    },
    selectSupplierCallback(supplier) {
      this.form.supplier_name = supplier.name;
      this.form.supplier_id = supplier.id;
      this.flags.showDialogSelectSupplier = false
    },
    loadStockLocations() {
      let self = this;
      window.axios.get(window.ANA.api_url + '/store/stock-locations',{}).then(function(response) {
        self.options.stockLocations = response.data.data;
      });
    },
    saveStock() {
      const self = this;

      self.flags.isSaving = true;

      window.axios.post(window.ANA.api_url + '/store/stocks',self.form).then(function(response) {
        self.propAddStockSuccessCallback(response);
      }).catch(function (error) {
        self.propAddStockFailedCallback(error);
      }).then(function (){
        self.flags.isSaving = false;
        self.resetForm();
      });
    }
  }
}
</script>