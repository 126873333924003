<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-data-table
            :page.sync="currentPage"
            :items-per-page="perPage"
            :headers="headers"
            :items="items"
            hide-default-footer
            class="elevation-1">
          <template v-slot:top>
            <v-toolbar flat>
              <v-text-field v-model="searchByName" hide-details prepend-icon="mdi-magnify" single-line label="Filter By Name"></v-text-field>
              <v-text-field v-model="searchByBarcode" hide-details prepend-icon="mdi-magnify" single-line label="Filter By Barcode"></v-text-field>
              <v-text-field v-model="searchBySKU" hide-details prepend-icon="mdi-magnify" single-line label="Filter By SKU"></v-text-field>
              <v-spacer></v-spacer>
              <v-btn color="success" depressed @click="dialogs.create = true">CREATE PRODUCT</v-btn>
            </v-toolbar>
          </template>
          <template v-slot:item.name="{ item }">
            <div style="padding: 5px;">
              <h3>{{item.name}}</h3>
              <div style="font-size: 12px;">
                <div><i>Barcode: {{item.barcode}}</i></div>
                <div><i>SKU: {{item.sku}}</i></div>
                <div><i>Entity: {{item.entity}}</i></div>
              </div>
            </div>
          </template>
          <template v-slot:item.stock_count="{ item }">
            <div style="width: 150px">
              {{item.stock_count}}
              <v-chip v-if="(item.stock_count >= 5)"
                      class="ma-2"
                      color="success">
                In Stock
              </v-chip>
              <v-chip v-else-if="(item.stock_count <= 4 && item.stock_count !== 0)"
                      class="ma-2"
                      color="warning">
                Low in Stocks
              </v-chip>
              <v-chip v-else
                      class="ma-2"
                      color="red">
                Out Of Stock
              </v-chip>
            </div>
          </template>
          <template v-slot:item.actions="{ item }">
            <div style="width: 50px">
              <v-icon title="Edit" small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
              <v-icon title="Delete" small class="mr-2" @click="deleteItem(item)">mdi-delete</v-icon>
            </div>
          </template>
        </v-data-table>
        <div class="text-center pt-2">
          <v-pagination
              v-model="currentPage"
              :length="pageCount">
          </v-pagination>
        </div>
      </v-col>
    </v-row>
    <v-row justify="center">

      <!-- dialog for product create -->
      <v-dialog v-model="dialogs.create" persistent max-width="900px">
        <v-card>
          <v-card-title class="primary">
            <span class="text-h5">Create Product</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="6">
                  <v-text-field v-model="productCreateForm.name" label="Name"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-combobox v-model="productCreateForm.entity" :items="entities" label="Product Entity"></v-combobox>
                </v-col>
                <v-col cols="6">
                  <v-text-field v-model="productCreateForm.barcode" label="Barcode"></v-text-field>
                  <v-btn class="ml-2" v-if="flags.scanMode === false" @click="flags.scanMode = true">START SCAN</v-btn>
                  <v-btn class="ml-2" v-if="flags.scanMode === true" @click="flags.scanMode = false">SCANNING..</v-btn>
                </v-col>
                <v-col cols="6">
                  <v-text-field v-model="productCreateForm.sku" label="SKU"></v-text-field>
                  <v-btn @click="generateSKU">Generate SKU</v-btn>
                </v-col>
                <v-col cols="4">
                  <v-text-field v-model="productCreateForm.retail_price" label="Retail Price"></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field v-model="productCreateForm.stock_count" label="Stock Count"></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-combobox v-model="productCreateForm.type" :items="productTypes" label="Product Type"></v-combobox>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn :disabled="isSaving" color="warning darken-1" @click="dialogs.create = false">
              Cancel
            </v-btn>
            <v-btn :disabled="isSaving" color="blue darken-1" @click="saveProduct">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- dialog for product create -->
      <v-dialog v-model="dialogs.edit" persistent max-width="900px">
        <edit-product-form
            :prop-edit-failed-callback="editFailedCallback"
            :prop-edit-success-callback="editSuccessCallback"
            :prop-edit-cancel-callback="editCancelCallback"
            :prop-product-types="productTypes"
            :prop-product="productEditForm">
        </edit-product-form>
      </v-dialog>

    </v-row>
  </v-container>
</template>

<script>
import {debounce} from "../../common/helpers";
import EditProductForm from "../../components/EditProductForm";
export default {
  name: 'Products',
  components: {EditProductForm},
  data () {
    return {
      headers: [
        { text: 'Name', value: 'name', align: 'start', sortable: false },
        { text: 'Type', value: 'type', align: 'start', sortable: false },
        { text: 'Retail Price', value: 'retail_price', align: 'start', sortable: false },
        { text: 'Stock Count', value: 'stock_count' , align: 'start, sortable: false'},
        { text: 'Author', value: 'author.name', align: 'start', sortable: false },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      entities: ['pharmacy','grocery'],
      items: [],
      currentPage: 1,
      perPage: 10,
      pageCount: 0,
      isSaving: false,
      searchByName: '',
      searchByBarcode: '',
      searchBySKU: '',

      dialogs: {
        create: false,
        edit: false,
      },
      flags: {
        scanMode: false
      },

      productTypes: [],

      productCreateForm: {
        name: '',
        entity: '',
        barcode: '',
        sku: '',
        retail_price: 0.00,
        type: '',
        stock_count: 1,
      },
      productEditForm: {}
    }
  },
  watch: {
    currentPage: function () {
      this.loadProducts();
    },
    searchByName: debounce(function (newVal) {
      this.searchByName = newVal
      this.loadProducts();
    }, 800),
    searchByBarcode: debounce(function (newVal) {
      this.searchByBarcode = newVal
      this.loadProducts();
    }, 800),
    searchBySKU: debounce(function (newVal) {
      this.searchBySKU = newVal
      this.loadProducts();
    }, 800),
    "flags.scanMode": debounce(function (newVal) {
      if(newVal === true) this.loadBarcode();
    }, 800)
  },
  mounted() {
    const self = this;
    self.loadProductTypes();
    self.loadProducts();

    self.loadBarcode();
  },
  methods: {
    clearForm() {
      this.productCreateForm.name = '';
      this.productCreateForm.barcode = '';
      this.productCreateForm.sku = '';
      this.productCreateForm.retail_price = 0.00;
      this.productCreateForm.type = 0.00;
      this.productCreateForm.stock_count = 0;
    },
    generateSKU() {
      let pname = this.productCreateForm.name;
      this.productCreateForm.sku = pname.replaceAll(" ","-").toLowerCase()
    },
    deleteItem(item) {
      let self = this
      this.$store.commit('showDialog',{
        title: 'Delete Product',
        message: 'Are you sure you want to delete this product? Product name: ' + item.name,
        okCb: () => {
          window.axios.delete(window.ANA.api_url + '/store/products/' + item.id).then(function(response) {
            console.log(response)
            self.loadProducts();
            self.$store.commit('showSnackbar',{
              message: "Product Deleted Successfully.",
              color: 'success',
              duration: 3000
            });
          });
        },
        cancelCb: () => { console.log("Deletion Cancelled.") },
      });
    },
    editItem(item) {
      console.log(item)
      this.productEditForm = item;
      this.dialogs.edit = true
    },
    editCancelCallback() {
      console.log('cancelled')
      this.productEditForm = {}
      this.dialogs.edit = false
    },
    editFailedCallback(error) {
      console.log(error)
      this.dialogs.edit = false;
      this.$store.commit('showSnackbar',{
        message: "Product Edit Failed.",
        color: 'red',
        duration: 3000
      });
    },
    editSuccessCallback(response) {
      console.log(response)
      this.dialogs.edit = false;
      this.$store.commit('showSnackbar',{
        message: "Product Updated Successfully.",
        color: 'success',
        duration: 3000
      });
      this.loadProductTypes();
      this.loadProducts();
    },
    saveProduct() {

      const self = this;

      self.isSaving = true;

      let payload = {
        name: self.productCreateForm.name,
        entity: self.productCreateForm.entity,
        barcode: self.productCreateForm.barcode,
        sku: self.productCreateForm.sku,
        retail_price: self.productCreateForm.retail_price,
        type: self.productCreateForm.type,
        stock_count: self.productCreateForm.stock_count,
      };

      window.axios.post(window.ANA.api_url + '/store/products',payload).then(function(response) {
        console.log(response);
        self.clearForm();
        self.loadProducts();
        self.loadProductTypes();
        self.isSaving = false;
        self.dialogs.create = false;
        self.$store.commit('showSnackbar',{
          message: "Product Created Successfully.",
          color: 'success',
          duration: 3000
        });
      }).catch(function (error) {
        console.log(error);
        self.isSaving = false;
        self.$store.commit('showSnackbar',{
          message: "Product Create Failed.",
          color: 'red',
          duration: 3000
        });
      });
    },
    loadProducts(cb) {

      let self = this;
      let params = {
        name: self.searchByName,
        barcode: self.searchByBarcode,
        sku: self.searchBySKU,
        current_page: self.currentPage,
        per_page: self.perPage,
      };

      window.axios.get(window.ANA.api_url + '/store/products',{params: params}).then(function(response) {
        self.items = response.data.data.items;
        self.pageCount = response.data.data.total_pages;
        (cb || Function)();
      });
    },
    loadProductTypes() {
      let self = this;
      window.axios.get(window.ANA.api_url + '/store/product-types',{}).then(function(response) {
        self.productTypes = response.data.data;
      });
    },
    loadBarcode() {
      let self = this;
      let params = {
        device_code: window.ANA.deviceID,
      };

      window.axios.get(window.ANA.api_url + '/store/getbcscan',{params: params}).then(function(response) {

        if(response.data.data.new === 'no') {
          console.log("No new Barcode")
        } else {
          console.log(response.data.data.barcode)
          self.productCreateForm.barcode = response.data.data.barcode;
          self.playBeep();
        }

        if(self.flags.scanMode === true) {
          setTimeout(function(){
            self.loadBarcode();
          },2000);
        }
      });
    },
    playBeep() {
      let context = new AudioContext();
      let oscillator = context.createOscillator();
      oscillator.type = "sine";
      oscillator.frequency.value = 800;
      oscillator.connect(context.destination);
      oscillator.start();
      // Beep for 500 milliseconds
      setTimeout(function () {
        oscillator.stop();
      }, 100);
    }
  },
}
</script>