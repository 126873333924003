<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-data-table
            :page.sync="currentPage"
            :items-per-page="perPage"
            :headers="headers"
            :items="items"
            hide-default-footer
            class="elevation-1">
          <template v-slot:top>
            <v-toolbar flat>
              <v-text-field v-model="searchBy.itemName" hide-details prepend-icon="mdi-magnify" single-line label="Filter By Item Name"></v-text-field>
              <v-text-field v-model="searchBy.itemCode" hide-details prepend-icon="mdi-magnify" single-line label="Filter By Item Code"></v-text-field>
              <v-text-field v-model="searchBy.supplier" hide-details prepend-icon="mdi-magnify" single-line label="Filter By Supplier"></v-text-field>
              <v-text-field v-model="searchBy.batch_number" hide-details prepend-icon="mdi-magnify" single-line label="Filter By Batch Number"></v-text-field>
              <v-text-field v-model="searchBy.dr_number" hide-details prepend-icon="mdi-magnify" single-line label="Filter By DR Number"></v-text-field>
              <v-spacer></v-spacer>
              <v-btn color="success" depressed @click="dialogs.create = true">ADD STOCK</v-btn>
            </v-toolbar>
          </template>
          <template v-slot:item.name="{ item }">
            <div>
              <h3>{{item.item_name}}</h3>
              <div style="font-size: 12px;">
                <div><i>Item Code: {{item.item_code}}</i></div>
                <div><i>Supplier: {{item.supplier.name}}</i></div>
              </div>
            </div>
          </template>
          <template v-slot:item.data="{ item }">
            <div style="font-size: 12px; padding: 5px;">
              <div>Batch No: {{item.batch_number}}</div>
              <div>DR No: {{item.dr_number}}</div>
              <div>Date Expiry: {{format(item.date_expire)}}</div>
              <div>Location: {{item.location}}</div>
            </div>
          </template>
          <template v-slot:item.actions="{ item }">
            <div style="width: 50px">
              <v-icon title="Edit" small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
              <v-icon title="Delete" small class="mr-2" @click="deleteItem(item)">mdi-delete</v-icon>
            </div>
          </template>
        </v-data-table>
        <div class="text-center pt-2">
          <v-pagination
              v-model="currentPage"
              :length="pageCount">
          </v-pagination>
        </div>
      </v-col>
    </v-row>
    <v-row justify="center">

      <!-- dialog for stocks create -->
      <v-dialog v-model="dialogs.create" persistent max-width="800px">
        <add-stock-form :prop-cancel-callback="cancel" :prop-add-stock-failed-callback="addStockFailedCB" :prop-add-stock-success-callback="addStockSuccessCB"></add-stock-form>
      </v-dialog>

      <v-dialog v-model="dialogs.edit" persistent max-width="800px">
        <edit-stock-form v-if="dialogs.edit"
            :prop-cancel-callback="cancelEdit"
            :prop-edit-stock-failed-callback="editStockFailedCallback"
            :prop-edit-stock-success-callback="editStockSuccessCallback"
            :prop-stock="stock">
        </edit-stock-form>
      </v-dialog>

    </v-row>
    <v-row justify="center">
      <v-dialog v-model="flags.showDialogSelectProduct" max-width="900px">
        <select-product :prop-select-callback="selectProductCallback"></select-product>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import SelectProduct from "../../components/SelectProduct";
import AddStockForm from "../../components/AddStockForm";
import EditStockForm from "../../components/EditStockForm";
import moment from "moment";
import {debounce} from "../../common/helpers";
export default {
  name: 'Stocks',
  components: {AddStockForm, EditStockForm, SelectProduct},
  watch: {
    currentPage: function () {
      this.loadStocks();
    },
    'searchBy.itemName': debounce(function () {
      this.loadStocks();
    },800),
    'searchBy.itemCode': debounce(function () {
      this.loadStocks();
    },800),
    'searchBy.supplier': debounce(function () {
      this.loadStocks();
    },800),
    'searchBy.batch_number': debounce(function () {
      this.loadStocks();
    },800),
    'searchBy.dr_number': debounce(function () {
      this.loadStocks();
    },800),
  },
  data () {
    return {
      headers: [
        { text: 'Product', value: 'name', align: 'start', sortable: false },
        { text: 'Item Data', value: 'data', align: 'start', sortable: false },
        { text: 'Qty', value: 'quantity', align: 'start', sortable: false },
        { text: 'Unit Price', value: 'unit_price', align: 'start', sortable: false },
        { text: 'Total', value: 'total', align: 'start', sortable: false },
        { text: 'Remarks', value: 'remarks', align: 'start', sortable: false },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      flags: {
        isSaving: false,
      },
      items: [],
      currentPage: 1,
      perPage: 10,
      pageCount: 0,
      isSaving: false,
      searchBy: {
        itemName: '',
        itemCode: '',
        supplier: '',
        batch_number: '',
        dr_number: '',
      },
      stock: '',

      dialogs: {
        create: false,
        edit: false,
      },
    }
  },
  mounted() {
    const self = this;
    self.loadStocks();
  },
  methods: {
    selectProductCallback(product) {

      console.log(product)

      let self = this
      self.searchBy.productLabel = product.name;
      self.searchBy.productId = product.id;
      self.flags.showDialogSelectProduct = false;

      self.loadStocks();
    },
    cancel() {
      this.dialogs.create = false;
    },
    addStockSuccessCB(data) {
      console.log(data);
      this.dialogs.create = false;
      this.loadStocks();
      this.$store.commit('showSnackbar',{
        message: "Stocks Added Successfully.",
        color: 'success',
        duration: 3000
      });
    },
    addStockFailedCB(data) {
      console.log(data);
      this.$store.commit('showSnackbar',{
        message: "Failed to add new stock.",
        color: 'red',
        duration: 3000
      });
    },
    cancelEdit() {
      this.dialogs.edit = false
    },
    editItem(stock) {
      this.stock = stock
      this.dialogs.edit = true
    },
    editStockFailedCallback(error){
      console.log(error)
      this.$store.commit('showSnackbar',{
        message: "Failed to update stock.",
        color: 'red',
        duration: 3000
      });
    },
    editStockSuccessCallback(response){
      console.log(response)
      this.dialogs.edit = false
      this.$store.commit('showSnackbar',{
        message: "Stock updated successfully.",
        color: 'success',
        duration: 3000
      });
    },
    deleteItem(item) {
      console.log(item)
      let self = this
      this.$store.commit('showDialog',{
        title: 'Delete Stock Record',
        message: 'Are you sure you want to delete this stock record? Stock Count will not be updated. You will have to manually edit the stock count.',
        okCb: () => {
          window.axios.delete(window.ANA.api_url + '/store/stocks/' + item.id).then(function(response) {
            console.log(response)
            self.loadStocks();
            self.$store.commit('showSnackbar',{
              message: "Stock Record Deleted Successfully.",
              color: 'success',
              duration: 3000
            });
          });
        },
        cancelCb: () => { console.log("Deletion Cancelled.") },
      });
    },
    format(date) {
      return moment(date).format('YYYY-MM-DD') + ' (' + moment(date).fromNow() + ')';
    },
    loadStocks(cb) {

      let self = this;
      let params = {
        item_name: self.searchBy.itemName,
        item_code: self.searchBy.itemCode,
        supplier_id: self.searchBy.supplier,
        batch_number: self.searchBy.batch_number,
        dr_number: self.searchBy.dr_number,
        current_page: self.currentPage,
        per_page: self.perPage,
      };

      window.axios.get(window.ANA.api_url + '/store/stocks',{params: params}).then(function(response) {
        self.items = response.data.data.items;
        self.pageCount = response.data.data.total_pages;
        (cb || Function)();
      });
    }
  }
}
</script>