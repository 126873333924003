<template>
  <v-card>
    <v-card-title class="primary">
      <span class="text-h5">Add New Customer Record</span>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="6">
            <v-text-field v-model="form.name" label="Name"></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field v-model="form.contact_number" label="Contact Number"></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="form.address" label="Address"></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field v-model="form.terms" label="Terms"></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field v-model="form.max_credit_line" label="Max Credit Line"></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn :disabled="flags.isSaving" color="warning darken-1" @click="cancel">
        Cancel
      </v-btn>
      <v-btn :disabled="flags.isSaving" color="blue darken-1" @click="saveCustomer">
        Save
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  props: {
    propAddCustomerSuccessCallback: {
      required: true
    },
    propAddCustomerFailedCallback: {
      required: true
    },
    propCancelCallback: {
      required: true
    },
  },
  data() {
    return {
      form: {
        name: '',
        address: '',
        contact_number: '',
        terms: 0,
        max_credit_line: 0,
      },
      flags: {
        isSaving: false
      }
    }
  },
  mounted() {
  },
  methods: {
    resetForm() {
      this.form.name = '';
      this.form.address = '';
      this.form.contact_number = '';
      this.form.terms = 0;
      this.form.max_credit_line = 0;
    },
    cancel() {
      this.propCancelCallback();
    },
    saveCustomer() {
      const self = this;

      self.flags.isSaving = true;

      window.axios.post(window.ANA.api_url + '/accounts/customers',self.form).then(function(response) {
        self.propAddCustomerSuccessCallback(response);
      }).catch(function (error) {
        self.propAddCustomerFailedCallback(error);
      }).then(function (){
        self.flags.isSaving = false;
        self.resetForm();
      });
    }
  }
}
</script>