import Vue from 'vue'
import VueRouter from 'vue-router'
import Admin from './views/admin/Admin'
import Login from './views/Login'
import Sales from "./views/admin/Sales";
import Products from "./views/admin/Products";
import Stocks from "./views/admin/Stocks";
import Customers from "./views/admin/Customers";
import Supplier from "./views/admin/Supplier";
import Reports from "./views/admin/Reports";
import SalesNew from "./views/admin/Sales-New";
import SalesView from "./views/admin/Sales-View";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Login',
        component: Login
    },
    {
        path: '/admin',
        name: 'Admin',
        component: Admin,
        children: [
            {
                path: 'sales',
                name: 'Admin.sales',
                component: Sales
            },
            {
                path: 'sales-new',
                name: 'Admin.sales.add',
                component: SalesNew
            },
            {
                path: 'sales/:id',
                name: 'Admin.sales.view',
                component: SalesView
            },
            {
                path: 'products',
                name: 'Admin.products',
                component: Products
            },
            {
                path: 'stocks',
                name: 'Admin.stocks',
                component: Stocks
            },
            {
                path: 'customers',
                name: 'Admin.customers',
                component: Customers
            },
            {
                path: 'supplier',
                name: 'Admin.supplier',
                component: Supplier
            },
            {
                path: 'reports',
                name: 'Admin.reports',
                component: Reports
            }
        ]
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    if(to.name==='Login') {
        next();
    }
    if(localStorage.getItem('ANA_session') !== null) {
        next();
    } else {
        console.log("You are unauthenticated.");
        router.push("/").catch(()=>{});
    }
});

export default router