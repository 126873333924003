<template>
  <v-app id="inspire">
    <v-main>
      <div id="app">
        <router-view/>

        <!-- loader -->
        <v-overlay :value="overlay">
          <v-progress-circular
              indeterminate
              size="64"
          ></v-progress-circular>
        </v-overlay>

        <!-- snackbar -->
        <v-snackbar
            :timeout="snackbarDuration"
            :color="snackbarColor"
            top
            v-model="showSnackbar">
          {{ snackbarMessage }}
        </v-snackbar>

        <!-- dialog confirm -->
        <v-dialog v-show="showDialog" v-model="showDialog" absolute max-width="450px">
          <v-card>
            <v-card-title>
              <div class="headline"><v-icon v-if="dialogIcon">{{dialogIcon}}</v-icon> {{ dialogTitle }}</div>
            </v-card-title>
            <v-card-text>{{ dialogMessage }}</v-card-text>
            <v-card-actions v-if="dialogType=='confirm'">
              <v-spacer></v-spacer>
              <v-btn color="primary darken-1" @click.native="dialogOk">Ok</v-btn>
              <v-btn color="warning darken-1" @click.native="dialogCancel">Cancel</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- the progress bar -->
        <vue-progress-bar></vue-progress-bar>
      </div>
    </v-main>
  </v-app>
</template>

<script>
  import store from './common/store'
  export default {
    methods: {
      dialogCancel () {
        store.commit('dialogCancel')
      },
      dialogOk() {
        store.commit('dialogOk')
      }
    },
    computed: {
      overlay () {
        return store.getters.showLoader
      },
      snackbarDuration () {
        return store.getters.snackbarDuration
      },
      snackbarColor () {
        return store.getters.snackbarColor
      },
      showSnackbar: {
        get() {
          return store.getters.showSnackbar;
        },
        set(val) {
          if(!val) store.commit('hideSnackbar');
        }
      },
      snackbarMessage () {
        return store.getters.snackbarMessage
      },
      showDialog () {
        return store.getters.showDialog
      },
      dialogIcon () {
        return store.getters.dialogIcon
      },
      dialogMessage () {
        return store.getters.dialogMessage
      },
      dialogTitle () {
        return store.getters.dialogTitle
      },
      dialogType () {
        return store.getters.dialogType
      }
    }
  }
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
