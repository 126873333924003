<template>
	<v-app id="inspire">
		<v-main>
			<v-container fluid fill-height>
				<v-layout align-center justify-center>
					<v-flex xs12 sm8 md4>
            <v-img
                contain
                width="100%"
                style="padding-bottom: 100px"
                src="../assets/logo.jpg"
            ></v-img>
						<v-card class="elevation-12">
							<v-toolbar dark color="primary">
								<v-toolbar-title>Admin Panel Login</v-toolbar-title>
							</v-toolbar>
							<v-card-text>
								<v-form>
									<v-text-field
											v-model="email"
											prepend-icon="person"
											name="login"
											label="Login"
											type="text"
									></v-text-field>
									<v-text-field
											v-model="password"
											id="password"
											prepend-icon="lock"
											name="password"
											label="Password"
											type="password"
									></v-text-field>
								</v-form>
							</v-card-text>
							<v-card-actions>
								<v-spacer></v-spacer>
								<v-btn color="primary" @click="submit">Login</v-btn>
								<v-btn color="warning" @click="clear">Clear</v-btn>
							</v-card-actions>
						</v-card>
					</v-flex>
				</v-layout>
			</v-container>
		</v-main>
	</v-app>
</template>

<script>
import store from '../common/store'
import axios from 'axios'
import router from "../router";
export default {
	name: 'Login',

	data: () => ({
		email: '',
		password: '',
	}),

	methods: {
		submit () {

			store.commit('showLoader');

			const self = this;

			let payload = {
				email: self.email,
				password: self.password,
				device_name: self.email,
			};

			axios.post(window.ANA.api_url + '/auth',payload).then(function(response) {

        store.commit('setUserLogin',{
          token: response.data.data.token,
          userData: response.data.data.user_data,
        });

        store.commit('hideLoader');
        store.commit('showSnackbar',{
          message: "Login successful.",
          color: 'success',
          duration: 3000
        });

        router.push({name: "Admin.sales"});

			}).catch(function (error) {

        console.log(error);
        store.commit('hideLoader');
        store.commit('showSnackbar',{
          message: "Invalid credentials.",
          color: 'error',
          duration: 3000
        });
			});
		},
		clear () {
			this.email = ''
			this.password = ''
		},
	},
};
</script>

<style></style>
