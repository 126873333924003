<template>
  <v-container>
    <v-row no-gutters>
      <v-col cols="9">
        <img src="../../assets/logo_white.png" height="80px">
      </v-col>
      <v-col cols="3">
        <v-container fluid fill-height>
           <v-btn depressed @click="logout" color="warning">
             <v-icon left>
               mdi-account
             </v-icon>
             Welcome, {{userName}} (Logout)
           </v-btn>
        </v-container>
      </v-col>
    </v-row>
    <v-divider style="margin: 10px 0;"></v-divider>
    <v-row no-gutters dense style="padding-top: 10px">
      <v-col cols="12" class="ANA_admin_menu">
        <v-tabs fixed-tabs background-color="indigo">
          <v-tab active-class="ANA_menu_active" :to="{name:'Admin.sales'}">
            <v-icon>mdi-cash</v-icon> Sales
          </v-tab>
          <v-tab active-class="ANA_menu_active" :to="{name:'Admin.products'}">
            <v-icon>mdi-cart</v-icon> Products
          </v-tab>
          <v-tab active-class="ANA_menu_active" :to="{name:'Admin.stocks'}">
            <v-icon>mdi-ballot</v-icon> Stocks
          </v-tab>
          <v-tab active-class="ANA_menu_active" :to="{name:'Admin.customers'}">
            <v-icon>mdi-account</v-icon> Customers
          </v-tab>
          <v-tab active-class="ANA_menu_active" :to="{name:'Admin.supplier'}">
            <v-icon>mdi-domain</v-icon> Suppliers
          </v-tab>
          <v-tab active-class="ANA_menu_active" :to="{name:'Admin.reports'}">
            <v-icon>mdi-chart-line-variant</v-icon> Reports
          </v-tab>
        </v-tabs>
      </v-col>
    </v-row>
    <v-row no-gutters dense style="padding-top: 10px">
      <v-col cols="12">
        <router-view/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import store from '@/common/store'
import router from "../../router";
export default {
  name: 'Admin',
  components: {},
  mounted() {

  },
  methods: {
    logout() {
      store.commit('logoutUser');
      router.push({name: "Login"});
    }
  },
  computed: {
    userName() {
      return store.getters.getUserData.name
    }
  }
}
</script>
<style>
.ANA_admin_menu a, .ANA_admin_menu a i {
  text-decoration: none;
}
a.ANA_menu_active {
  font-weight: bold;
  color: white !important;
}
</style>