<template>
  <v-container>
    <v-row>
      <v-col cols="6">
        <v-card>
          <v-card-title>
            TOTAL SALES TODAY: {{sales.today}}
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card>
          <v-card-title>
            TOTAL SALES THIS MONTH: {{sales.thisMonth}}
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-data-table
            :page.sync="currentPage"
            :items-per-page="perPage"
            :headers="headers"
            :items="items"
            hide-default-footer
            class="elevation-1">
          <template v-slot:top>
            <v-toolbar flat>
              <v-text-field v-model="searchBy.invoice_id" hide-details prepend-icon="mdi-magnify" single-line label="Search By Invoice"></v-text-field>
              <v-text-field v-model="searchBy.payment_type" hide-details prepend-icon="mdi-magnify" single-line label="Search By Payment Type"></v-text-field>
              <v-text-field v-model="searchBy.status" hide-details prepend-icon="mdi-magnify" single-line label="Search By status"></v-text-field>
              <v-spacer></v-spacer>
              <v-btn color="success" depressed :to="{name:'Admin.sales.add'}">NEW SALES</v-btn>
            </v-toolbar>
          </template>
          <template v-slot:item.id="{ item }">
            <router-link :to="{name:'Admin.sales.view',params:{id: item.id}}">INVOICE#00{{item.id}}</router-link>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon title="Delete" small class="mr-2" @click="deleteItem(item)">mdi-delete</v-icon>
          </template>
          <template v-slot:item.date_sold="{ item }">
            {{format(item.date_sold)}}
          </template>
        </v-data-table>
        <div class="text-center pt-2">
          <v-pagination
              v-model="currentPage"
              :length="pageCount">
          </v-pagination>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {debounce, formatDate} from "../../common/helpers";
import moment from "moment";

export default {
  name: 'Sales',
  components: {},
  watch: {
    currentPage: function () {
      this.loadInvoices();
    },
    'searchBy.invoice_id': debounce(function () {
      this.loadInvoices();
    },800),
    'searchBy.payment_type': debounce(function () {
      this.loadInvoices();
    },800),
    'searchBy.status': debounce(function () {
      this.loadInvoices();
    },800),
  },
  data () {
    return {
      headers: [
        { text: 'Invoice ID#', value: 'id', align: 'start', sortable: false },
        { text: 'Customer', value: 'customer_name', align: 'start', sortable: false },
        { text: 'Gross', value: 'gross_total', align: 'start', sortable: false },
        { text: 'Net', value: 'net_total', align: 'start', sortable: false },
        { text: 'Payment Type', value: 'payment_type', align: 'start', sortable: false },
        { text: 'Status', value: 'status', align: 'start', sortable: false },
        { text: 'Date Sold', value: 'date_sold', align: 'start', sortable: false },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      sales: {
        today: 0.00,
        thisMonth: 0.00,
      },
      items: [],
      currentPage: 1,
      perPage: 10,
      pageCount: 0,
      isSaving: false,
      searchBy: {
        invoice_id: '',
        payment_type: '',
        status: '',
      },
    }
  },
  mounted() {
    const self = this;
    self.loadInvoices();
    self.loadTotalSalesData();
  },
  methods: {
    format(d) {
      return formatDate(d)
    },
    editItem(item) {
      console.log(item)
    },
    deleteItem(item) {
      console.log(item)
      let self = this
      this.$store.commit('showDialog',{
        title: 'Delete Sales Record',
        message: 'Are you sure you want to delete this Sales Record? Please note that when you delete this invoice, you will have to manually update stock count of the affected product manually.',
        okCb: () => {
          window.axios.delete(window.ANA.api_url + '/pos/invoices/' + item.id).then(function(response) {
            console.log(response)
            self.loadInvoices();
            self.$store.commit('showSnackbar',{
              message: "Invoice Deleted Successfully.",
              color: 'success',
              duration: 3000
            });
          });
        },
        cancelCb: () => { console.log("Deletion Cancelled.") },
      });
    },
    loadInvoices(cb) {

      let self = this;
      let params = {
        id: self.searchBy.invoice_id,
        payment_type: self.searchBy.payment_type,
        status: self.searchBy.status,
        current_page: self.currentPage,
        per_page: self.perPage,
      };

      window.axios.get(window.ANA.api_url + '/pos/invoices',{params: params}).then(function(response) {
        self.items = response.data.data.items;
        self.pageCount = response.data.data.total_pages;
        (cb || Function)();
      });
    },
    loadTotalSalesData() {

      let self = this

      let paramsToday = {
        start_date: moment().format('YYYY-MM-DD'),
        end_date: moment().format('YYYY-MM-DD'),
      };

      let paramsThisMonth = {
        start_date: moment().startOf('month').format('YYYY-MM-DD'),
        end_date: moment().endOf('month').format('YYYY-MM-DD'),
      };

      window.axios.get(window.ANA.api_url + '/reports/sales-range',{params: paramsToday}).then(function(response) {
        self.sales.today = response.data.data
      });
      window.axios.get(window.ANA.api_url + '/reports/sales-range',{params: paramsThisMonth}).then(function(response) {
        self.sales.thisMonth = response.data.data
      });
    }
  }
}
</script>