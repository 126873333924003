<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-divider></v-divider>
      </v-col>
      <v-col cols="4">
        <v-text-field label="START DATE" v-model="dateRange.startDate" v-mask="'####-##-##'"></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field label="END DATE" v-model="dateRange.endDate" v-mask="'####-##-##'"></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-btn class="primary" x-large @click="loadReport">Generate Report</v-btn>
      </v-col>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            TOTAL SALES: {{totalSales}}
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card
            class="mx-auto text-center"
            dark>
          <v-card-text>
            <v-sheet color="blue">
              <line-chart v-if="chart.loaded" :chart-data="chart.dataCollection"></line-chart>
            </v-sheet>
          </v-card-text>
          <v-card-text>
            <div class="text-h4 font-weight-thin">
              DAILY SALES
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-tabs
            centered
            fixed-tabs
            v-model="tab"
            align-with-title>
          <v-tabs-slider color="blue"></v-tabs-slider>
          <v-tab key="gross_sales">SALES REPORT BY GROSS SALES</v-tab>
          <v-tab key="sales_volume">SALES REPORT BY SALES VOLUME</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item key="gross_sales">
            <v-simple-table dark>
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-left">
                    Product Name
                  </th>
                  <th class="text-left">
                    GROSS SALES (PHP)
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in productSales" :key="item.name">
                  <td>{{ item.name }}</td>
                  <td>
                    <v-progress-linear
                        :disabled="true"
                        v-model="item.prog"
                        color="blue"
                        height="25">
                      <strong>{{ item.gross_sales }} PHP</strong>
                    </v-progress-linear>
                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-tab-item>
          <v-tab-item key="sales_volume">
            <v-simple-table dark>
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-left">
                    Product Name
                  </th>
                  <th class="text-left">
                    GROSS SALES (PHP)
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in productSalesByVolume" :key="item.name">
                  <td>{{ item.name }}</td>
                  <td>
                    <v-progress-linear
                        :disabled="true"
                        v-model="item.prog"
                        color="blue"
                        height="25">
                      <strong>{{ item.quantities_sold }} Item(s) Sold</strong>
                    </v-progress-linear>
                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LineChart from "../../components/LineChart";
import moment from "moment";
export default {
  name: 'Reports',
  components: {LineChart},
  mounted() {
    this.loadReport();
  },
  data() {
    return {
      dateRange: {
        startDate: moment().startOf('month').format('YYYY-MM-DD'),
        endDate: moment().endOf('month').format('YYYY-MM-DD'),
      },
      chart: {
        loaded: false,
        dataCollection: null
      },
      productSales: [],
      productSalesByVolume: [],
      tab: 'gross_sales',
      totalSales: 0.00
    }
  },
  methods: {
    formatToChart(data) {
      let cd = {
        labels: [],
        data: []
      }
      data.forEach(function(item) {
        cd.labels.push(item.date)
        cd.data.push(parseFloat(item.gross_sales))
      })
      this.chart.dataCollection = {
        labels: cd.labels,
        datasets: [
          {
            label:'Daily Sales',
            data: cd.data
          }
        ]
      }
      this.chart.loaded = true
    },
    loadReport() {
      let self = this;
      self.loadDailySales();
      self.loadTotalSalesData();
      self.loadProductSalesReportByGross();
      self.loadProductSalesReportBySoldVolume();
    },
    loadProductSalesReportByGross() {
      let self = this;
      let params = {
        start_date: self.dateRange.startDate,
        end_date: self.dateRange.endDate,
      };
      window.axios.get(window.ANA.api_url + '/reports/generate-product-sales-by-gross-sales',{params: params})
          .then(function (res){

            self.productSales = res.data.data

            let max = parseFloat(self.productSales[0].gross_sales)

            self.productSales.forEach(function(item) {
              item.prog = ((parseFloat(item.gross_sales) * 100) / max).toFixed(2)
            })
          });
    },
    loadProductSalesReportBySoldVolume() {
      let self = this;
      let params = {
        start_date: self.dateRange.startDate,
        end_date: self.dateRange.endDate,
      };
      window.axios.get(window.ANA.api_url + '/reports/generate-product-sales-by-sold-volume',{params: params})
          .then(function (res){

            self.productSalesByVolume = res.data.data

            let max = parseFloat(self.productSalesByVolume[0].quantities_sold)

            self.productSalesByVolume.forEach(function(item) {
              item.prog = ((parseFloat(item.quantities_sold) * 100) / max).toFixed(2)
            })
          });
    },
    loadDailySales() {
      let self = this;
      let params = {
        start_date: self.dateRange.startDate,
        end_date: self.dateRange.endDate,
      };

      window.axios.get(window.ANA.api_url + '/reports/generate-daily-sales',{params: params})
          .then(function (res){
            console.log(res.data)
            self.formatToChart(res.data.data)
          });
    },
    loadTotalSalesData() {

      let self = this

      let paramsThisMonth = {
        start_date: self.dateRange.startDate,
        end_date: self.dateRange.endDate,
      };

      window.axios.get(window.ANA.api_url + '/reports/sales-range',{params: paramsThisMonth}).then(function(response) {
        self.totalSales = response.data.data
      });
    }
  }
}
</script>